<template>
  <div class="Otherspersonal">
    <div class="leftbox">
      <Mine :MemberCode="list" @getuserinfo="getuserinfo" />
    </div>
    <div class="rightbox">
      <div class="tiezi">
        <div class="category">
          <div :class="index == 0 ? 'action' : 'disted'" @click="setindex(0)">
            帖子（{{ list.MemberData.FindCount }}）
          </div>
          <div :class="index == 1 ? 'action' : 'disted'" @click="setindex(1)">
            干货（{{ list.MemberData.FindDryGoodsCount }}）
          </div>
        </div>
        <div class="paixu">
          <el-dropdown>
            <span class="el-dropdown-link">
              排序<i class="el-icon-sort"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>点赞排序</el-dropdown-item>
              <el-dropdown-item>游览排序</el-dropdown-item>
              <el-dropdown-item>回复排序</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="listbox">
        <List v-if="index == 0" :MemberCode="form.MemberCode" />
        <Listgh :MemberCode="form.MemberCode" v-if="index == 1" />
      </div>
    </div>
  </div>
</template>

<script>
import Mine from "./common/mine.vue";
import List from "./common/list.vue";
import Listgh from "./common/listGH.vue";
export default {
  data() {
    return {
      list: {
        MemberData: {},
      },
      form: {
        MemberCode: "",
        token: window.localStorage.getItem("token"),
      },
      index: 0,
    };
  },
  components: {
    Mine,
    List,
    Listgh,
  },
  created() {
    this.getuserinfo();
  },
  methods: {
    // 切换list
    setindex(index) {
      this.index = index;
    },
    getuserinfo() {
      if (this.$route.query.id) {
        this.form.MemberCode = this.$route.query.id;
      }
      this.$api.common.MemberInfo(this.form).then((res) => {
        console.log(res);
        this.list = res.data;
      });
    },
  },
};
</script>

<style lang='less' scoped>
@import url("./index.less");
</style>