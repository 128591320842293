<template>
  <div class="list">
    <div class="box" v-for="(item, index) in list" :key="index">
      <div class="messgein">
        <!-- <div class="name">{{ item.Name }}</div> -->
        <div class="conter">
          <div class="left">
            <span
              style="color: #e6bf84"
              v-if="item.Title != ''"
              @click="gotoconversation(item)"
              >#{{ item.Title }}#</span
            >
            <p
              style="color: #818181"
              v-html="item.Content"
              @click="gotoPostdetails(item)"
            >
              <!-- {{ item.Content }} -->
            </p>
          </div>
          <div class="right">
            <div style="">
              <i class="el-icon-chat-dot-round" style="margin-right: 5px"></i
              >{{ item.Comment }}
            </div>
            <div>{{ item.time }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    MemberCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      list: [],
      total: 0,
      params: {
        token: window.localStorage.getItem("token"),
        MemberCode: "",
        LabelName: "",
        pageIndex: 1,
        pageSize: 20,
      },
    };
  },
  created() {
    this.getMemberFindList();
  },
  methods: {
    // 去话题
    gotoconversation(item) {
      this.$router.push(`conversation?topic=${item.Title}`);
    },
    getMemberFindList() {
      this.params.MemberCode = this.MemberCode;
      this.$api.common.MemberFindList(this.params).then((res) => {
        if (res.code == 200) {
          for (let index = 0; index < res.data.list.length; index++) {
            res.data.list[index].Content = this.RichContent(
              res.data.list[index].Content
            );
          }
          this.list = res.data.list;

          this.total = res.data.total;
        }
      });
    },
    gotoPostdetails(item) {
      this.$router.push(`Postdetails?FindCode=${item.FindCode}`);
    },
    // 下一页
    handleCurrentChange(val) {
      this.params.pageIndex = val;
      this.getMemberFindList();
    },
  },
};
</script>

<style lang='less' scoped>
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e6bf84 !important;
}
.list {
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px;
  margin-bottom: 50px;
  .box {
    width: 100%;
    height: 82px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f8f8f8;

    .messgein {
      width: 100%;
      .name {
        font-size: 18px;
        font-family: NotoSansHans;
        font-weight: normal;
        color: #818181;
        cursor: pointer;
        margin-bottom: 5px;
      }
      .conter {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .left {
          width: 560px;
          white-space: nowrap;
          font-size: 14px;
          font-family: NotoSansHans;
          font-weight: 100;
          display: flex;
          align-items: center;
          cursor: pointer;
          p {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0;
            margin: 0;
          }
        }
        .right {
          display: flex;
          align-items: center;
          color: #898989;
          cursor: pointer;
          div {
            width: 80px;
            margin-left: 20px;
          }
        }
      }
    }
  }
  .pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
