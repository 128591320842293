<template>
  <div class="mine">
    <div class="Avatar">
      <img :src="MemberCode.MemberData.Avatar" alt="" />

      <div class="Grade" v-if="MemberCode.MemberData.Gender == '女'">
        {{ MemberCode.MemberData.Grade }}
      </div>
      <div class="Grademan" v-if="MemberCode.MemberData.Gender == '男'">
        {{ MemberCode.MemberData.Grade }}
      </div>
    </div>
    <div class="name">{{ MemberCode.MemberData.Name }}</div>
    <div class="fame">{{ MemberCode.MemberData.PersonalSignature }}</div>
    <div class="msg">
      <div class="box">
        <div>{{ MemberCode.MemberData.AttentionCount }}</div>
        <div>关注</div>
      </div>
      <div class="box">
        <div>{{ MemberCode.MemberData.FansCount }}</div>
        <div>粉丝</div>
      </div>
      <div class="box">
        <div>{{ MemberCode.MemberData.FindCount }}</div>
        <div>帖子</div>
      </div>
    </div>
    <el-divider></el-divider>
    <div
      :class="MemberCode.Whether_to_follow == 0 ? 'follow' : 'newfollow'"
      @click="setWhether_to_follow(MemberCode)"
    >
      {{ MemberCode.Whether_to_follow | Whether_to_follow }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    MemberCode: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    // 关注
    setWhether_to_follow(item) {
      console.log(item);
      let obj = {
        token: window.localStorage.getItem("token"),
        FollowedCode: item.MemberData.MemberCode,
      };
      if (item.Whether_to_follow == 0) {
        this.$api.common.MemberAttentionAdd(obj).then((res) => {
          if (res.code == 200) {
            this.$emit("getuserinfo");
          }
        });
      } else {
        this.$api.common.MemberAttentionDelete(obj).then((res) => {
          if (res.code == 200) {
            this.$emit("getuserinfo");
          }
        });
      }
    },
  },
  filters: {
    Whether_to_follow(val) {
      if (val == 1) {
        return "已关注";
      } else {
        return "关注";
      }
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-divider--horizontal {
  background-color: #f8f8f8;
}
.mine {
  width: 283px;
  min-height: 364px;
  background: url("../../../static/img/bg2.png") no-repeat;
  background-size: cover;
  border-radius: 14px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  .Avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: auto;
    margin-top: 60px;
    position: relative;
    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
    .Grade {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 22px;
      height: 22px;
      background: #fa8fd1;
      border: 1px solid #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      font-family: NotoSansHans;
      font-weight: 400;
      color: #ffffff;
    }
    .Grademan {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 22px;
      height: 22px;
      background: #836dfb;
      border: 1px solid #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      font-family: NotoSansHans;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-family: NotoSansHans;
    font-weight: 550;
    margin-bottom: 7px;
    color: #818181;
    margin-top: 3px;
  }
  .fame {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: NotoSansHans;
    font-weight: 100;
    color: #818181;
    opacity: 0.8;
    margin-top: 3px;
    line-height: 24px;
  }
  .msg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      line-height: 25px;
      div {
        font-size: 14px;
        font-family: NotoSansHans;
        font-weight: 100;
        color: #818181;
        opacity: 0.8;
      }
      div:nth-child(1) {
        font-size: 18px;
        font-family: NotoSansHans;
        font-weight: 400;
        color: #818181;
        opacity: 0.8;
      }
    }
  }
  .follow {
    width: 240px;
    height: 40px;
    margin-bottom: 10px;
    background: #e6bf84;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: NotoSansHans;
    font-weight: 300;
    color: #ffffff;
    cursor: pointer;
  }
  .follow {
    width: 240px;
    height: 40px;
    margin-bottom: 10px;
    background: #e6bf84;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: NotoSansHans;
    font-weight: 300;
    color: #ffffff;
    cursor: pointer;
  }
  .newfollow {
    width: 240px;
    height: 40px;
    margin-bottom: 10px;
    background: #f8f8f8;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: NotoSansHans;
    font-weight: 300;
    color: #887f7f;
    cursor: pointer;
  }
}
</style>
